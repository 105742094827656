import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import useHomeStore from '@/store/useHomeStore';
import {useSettingWindowDimensions} from '@/store/useSettingStore';
import theme from '@/style';
import React, {useCallback, useEffect} from 'react';
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {ScrollView} from 'react-native';
import {useShallow} from 'zustand/react/shallow';

const tabsList = [
  {title: 'Home', value: 0},
  // {title: 'Popular', value: 10},
  {title: 'Lottery', value: 1},
  {title: 'Casino', value: 2},
  {title: 'Live', value: 3},
  // {title: 'Sport'},
];

const HomeCategoryPageTabs = () => {
  const {screenWidth} = useSettingWindowDimensions();
  const {
    isShowCategoryTab,
    oneCategoryPageIndex,
    changeIndex,
    getLotteryPageData,
    getHomeTagList,
    getCategoryHomeList,
  } = useHomeStore(
    useShallow(state => ({
      isShowCategoryTab: state.isShowCategoryTab,
      oneCategoryPageIndex: state.oneCategoryPageIndex,
      changeIndex: state.setState,

      getLotteryPageData: state.getLotteryPageData,
      getHomeTagList: state.getHomeTagList,
      getCategoryHomeList: state.getCategoryHomeList,
    })),
  );

  const height = useSharedValue(42);

  const derivedHeight = useDerivedValue(
    () =>
      withTiming(isShowCategoryTab ? height.value : 0, {
        duration: 500,
      }),
    [isShowCategoryTab],
  );

  const bodyStyle = useAnimatedStyle(
    () => ({
      height: derivedHeight.value,
    }),
    [derivedHeight],
  );

  //其实应该传id后续看需要修改不
  const onPressItem = useCallback(
    (index: number) => {
      // 需要更改oneCategoryPageIndex和pageTagIndex两个值
      //每次切换oneCategoryPageIndex需要把pageTagIndex重置
      changeIndex({
        oneCategoryPageIndex: index,
        pageTagIndex: -1,
      });
    },
    [changeIndex],
  );

  useEffect(() => {
    useHomeStore.setState({isShowCategoryTab: true});
  }, []);

  useEffect(() => {
    useHomeStore.setState({pageTagIndex: -1});
    if (oneCategoryPageIndex === 10) {
      getLotteryPageData();
    }
    if (
      oneCategoryPageIndex === 1 ||
      oneCategoryPageIndex === 2 ||
      oneCategoryPageIndex === 3
    ) {
      getHomeTagList();
      getCategoryHomeList();
    }
  }, [
    getCategoryHomeList,
    getHomeTagList,
    getLotteryPageData,
    oneCategoryPageIndex,
  ]);

  return (
    <Animated.View
      style={[
        theme.margin.lrl,
        theme.borderRadius.m,
        // eslint-disable-next-line react-native/no-inline-styles
        bodyStyle,
      ]}>
      <ScrollView
        style={[
          theme.borderRadius.m,
          theme.flex.flex1,
          theme.background.primary15,
          // eslint-disable-next-line react-native/no-inline-styles
          {height: 42, width: screenWidth - theme.paddingSize.l * 2},
        ]}
        // eslint-disable-next-line react-native/no-inline-styles
        contentContainerStyle={{
          flex: 1,
          alignItems: 'center',
        }}
        horizontal
        showsHorizontalScrollIndicator={false}>
        {tabsList.map(item => (
          <NativeTouchableOpacity
            onPress={() => onPressItem(item?.value)}
            key={item?.value}
            style={[
              theme.flex.flex1,
              theme.flex.center,
              oneCategoryPageIndex === item?.value
                ? // eslint-disable-next-line react-native/no-inline-styles
                  {
                    ...theme.background.primary,
                    ...theme.borderRadius.l,
                    height: 35,
                  }
                : // eslint-disable-next-line react-native/no-inline-styles
                  {
                    height: 42,
                  },
            ]}>
            <Text size="medium" blod white>
              {item?.title}
            </Text>
          </NativeTouchableOpacity>
        ))}
      </ScrollView>
    </Animated.View>
  );
};

export default HomeCategoryPageTabs;
