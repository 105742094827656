import {defaultHeaderImg, headerSize} from '@/pages/me/me.variable';
import theme from '@style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React from 'react';
import {View, Image} from 'react-native';
import LazyImage from '@basicComponents/image';
import Text from '@basicComponents/text';
import {IUserInfo} from '@services/global.service';
import {vipOptionsMap} from '@businessComponents/vip';
import i18n from '@i18n';

const {flex, font, margin} = theme;
import Button from '@basicComponents/button';
import globalStore from '@/services/global.state';
import {goTo} from '@/utils';

interface DrawerUserProps {
  login?: boolean;
  user?: IUserInfo;
  level: number;
  onUser?: () => void;
  onClose: () => void;
}

const DrawerUser: React.FC<DrawerUserProps> = ({
  login,
  user,
  level,
  onClose,
  // onUser,
}) => {
  return (
    <View
      style={[
        flex.row,
        flex.between,
        flex.centerByCol,
        theme.padding.lrl,
        theme.padding.tbm,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          borderBottomColor: '#4C489380',
          borderBottomWidth: 1,
        },
      ]}>
      <View style={[flex.row, flex.center, flex.between]}>
        {login ? (
          <View style={[flex.row, flex.centerByRow, theme.gap.m]}>
            <LazyImage
              occupancy={'transparent'}
              radius={50}
              resizeMode="cover"
              imageUrl={user?.userAvatar ? user.userAvatar : defaultHeaderImg}
              width={(globalStore.screenWidth * headerSize) / 375}
              height={(globalStore.screenWidth * headerSize) / 375}
            />
            <View style={[theme.flex.col]}>
              <View style={[flex.row]}>
                <Text white blod style={[font.m, margin.rights]}>
                  {user?.userName ? user?.userName : user?.userPhone}
                </Text>
                <LazyImage
                  occupancy="#0000"
                  imageUrl={vipOptionsMap[level].sign}
                  width={(globalStore.screenWidth * 45) / 375}
                  height={(globalStore.screenWidth * 20) / 375}
                />
              </View>
              <View style={[flex.row, margin.tops, flex.centerByCol]}>
                <LazyImage
                  occupancy={'transparent'}
                  imageUrl={require('@assets/icons/home/home-menu/mobile.webp')}
                  width={10}
                  height={14}
                />
                <Text
                  white
                  style={[margin.leftxxs, margin.rightxxl]}
                  fontSize={theme.fontSize.s}>
                  {user?.userPhone}
                </Text>
                <LazyImage
                  occupancy={'transparent'}
                  imageUrl={require('@assets/icons/home/home-menu/userId.webp')}
                  width={14}
                  height={14}
                />
                <Text
                  white
                  style={[margin.leftxxs]}
                  fontSize={theme.fontSize.s}>
                  {+(user?.userId || 0) + 100000}
                </Text>
              </View>
            </View>
          </View>
        ) : (
          <View style={[flex.row, flex.centerByCol, theme.gap.m]}>
            <Image
              // eslint-disable-next-line react-native/no-inline-styles
              style={[{width: 145, height: 26}]}
              source={require('@assets/icons/home/logo-horizontal.webp')}
            />
            <Button
              title={i18n.t('me.user.loginUpper')}
              type="linear-primary"
              titleBold
              size="small"
              onPress={() => {
                onClose();
                goTo('Login');
              }}
              radius={40}
            />
          </View>
        )}
      </View>
      <NativeTouchableOpacity onPress={onClose}>
        <Image
          // eslint-disable-next-line react-native/no-inline-styles
          style={[{height: 28, width: 28}]}
          source={require('@assets/icons/close-white.webp')}
        />
      </NativeTouchableOpacity>
    </View>
  );
};

export default DrawerUser;
