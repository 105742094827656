import React from 'react';
import {View, Image, ImageRequireSource} from 'react-native';
import Text from '@basicComponents/text';
import theme from '@style';
import globalStore from '@/services/global.state';
import LinearGradient from '@/components/basic/linear-gradient';
import {useSettingWindowDimensions} from '@/store/useSettingStore';
import Button from '@/components/basic/button';
import {goTo} from '@/utils';
import {useToken} from '@/store/useUserStore';

type BtnState = {
  title: string;
  subTitle: string;
  bgColor: string[];
  source: ImageRequireSource;
};

const HomeFindHero = () => {
  const {screenWidth} = useSettingWindowDimensions();
  const {isLogin} = useToken();
  const itemWidth = (screenWidth - 12 * 2 - 10) / 2;
  const itemHeight = (itemWidth / 165) * 221;
  const headerImageWH = (itemWidth / 165) * 98;

  const renderItem = (item: BtnState) => {
    return (
      <LinearGradient
        key={item?.title}
        colors={item?.bgColor}
        style={[
          theme.borderRadius.s,
          theme.flex.centerByCol,
          // eslint-disable-next-line react-native/no-inline-styles
          {height: itemHeight, width: itemWidth, borderRadius: 21},
        ]}>
        <Image
          style={[
            theme.margin.topl,
            // eslint-disable-next-line react-native/no-inline-styles
            {height: headerImageWH, width: headerImageWH},
          ]}
          source={item?.source}
        />
        <Text
          fontFamily="fontInter"
          fontSize={22}
          white
          blod
          style={[theme.margin.topl]}>
          {item?.title}
        </Text>
        <Text
          fontFamily="fontInter"
          fontSize={12}
          color={theme.fontColor.white60}
          style={[theme.margin.topl, theme.font.center]}>
          {item?.subTitle}
        </Text>
      </LinearGradient>
    );
  };

  return (
    <View style={[theme.padding.lrl, theme.flex.centerByCol]}>
      <Image
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {height: 64, width: 64, marginTop: 20},
        ]}
        source={require('@assets/imgs/home/trophy.webp')}
      />
      <View style={[theme.flex.center]}>
        <Text fontSize={24} blod color={'#AD86FF'} style={[theme.font.center]}>
          Play game with our heroes
        </Text>
      </View>
      <Image
        style={[
          theme.margin.topxxl,
          theme.margin.btml,
          // eslint-disable-next-line react-native/no-inline-styles
          {height: globalStore.screenWidth, width: globalStore.screenWidth},
        ]}
        source={require('@assets/imgs/home/hero-image.webp')}
      />
      <View
        // eslint-disable-next-line react-native/no-inline-styles
        style={[theme.flex.row, theme.flex.wrap, {gap: 10}]}>
        {[
          {
            bgColor: ['#1F007600', '#260092FF'],
            source: require('@assets/imgs/home/hero-2.webp'),
            title: 'Play',
            subTitle: 'Earn cash for playing games and placing bets',
          },
          {
            bgColor: ['#764F0000', '#764F00ff'],
            source: require('@assets/imgs/home/hero-1.webp'),
            title: 'Unlock',
            subTitle: 'Unlock more mystery bonuses',
          },
          {
            bgColor: ['#61007600', '#712A89FF'],
            source: require('@assets/imgs/home/hero-4.webp'),
            title: 'Vip Level',
            subTitle: 'The more you play, the higher you’ll level',
          },
          {
            bgColor: ['#3E615000', '#1C5538FF'],
            source: require('@assets/imgs/home/hero-3.webp'),
            title: 'Earn',
            subTitle: 'Reaching a high rank leads to important rewards',
          },
        ].map(item => {
          return renderItem(item);
        })}
      </View>
      {!isLogin ? (
        <View style={[theme.margin.topxxl]}>
          <Button
            title={'Find You Hero'}
            type="linear-primary"
            radius={30}
            onPress={() => {
              goTo('Login');
            }}
          />
        </View>
      ) : null}
    </View>
  );
};

export default HomeFindHero;
