import Text from '@basicComponents/text';
import React, {useState, useCallback} from 'react';
import {ScrollView, View, Image, ImageRequireSource} from 'react-native';
import theme from '@style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import globalStore from '@services/global.state';
import Tag from '@basicComponents/tag';
import {homeDrawerStyle} from '../home.style';
import {checkToken, goTo, goCS, downloadApk, scaleSize} from '@/utils';
import {useLanguageModal} from '@/components/business/language';
import {useTranslation} from 'react-i18next';
import {languagesMap} from '@/i18n';
import DrawerUser from './drawer-user';
import {useFocusEffect} from '@react-navigation/native';
import {IUserInfo, postUserInfo} from '@services/global.service';
import DrawerGoldArea from './drawer-gold-area';
// import {toLogin} from '@/pages/me/me.variable';
import LinearGradient from '@basicComponents/linear-gradient';
import {useLuckySpinActions} from '@/store/luckySpinStore';
import useVipStore, {useVipActions} from '@/store/useVipStore';
import useHomeStore from '@/store/useHomeStore';
// import DrawerSns from './drawer-sns';

const HomeMenu = (props: {
  setMenuOpen: (bool: boolean) => void;
  unreadCount?: number;
}) => {
  const {setMenuOpen} = props;
  const {i18n} = useTranslation();
  const {renderModal, show: showLanguageModal} = useLanguageModal();
  const {setSpinConfig} = useLuckySpinActions();

  const onCloseDrawerMenu = () => {
    setMenuOpen(false);
  };

  const {level} = useVipStore(state => state.vipInfo);
  const {setVipConfig, setVipInfo} = useVipActions();

  const [login, setLogin] = useState(false);
  const [user, setUser] = useState<IUserInfo>();

  const refresh = useCallback(
    async (token: string | null) => {
      try {
        if (!token) {
          setVipConfig();
        } else {
          setVipInfo();
          const results = await Promise.allSettled([postUserInfo()]);
          const [userInfo] = results;
          if (userInfo.status === 'fulfilled') {
            setUser(userInfo.value);
            globalStore.userInfo = userInfo.value;
          }
        }
      } finally {
      }
    },
    [setVipConfig, setVipInfo],
  );

  const onFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
      refresh(token);
      setSpinConfig(!!token);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [refresh, setSpinConfig]);
  useFocusEffect(onFocusEffect);

  const renderFooterBtn = ({
    title,
    source,
    onPress,
    num,
    showDownIcon = false,
  }: {
    title: string;
    source: ImageRequireSource;
    onPress: () => void;
    num?: number;
    showDownIcon?: boolean;
  }) => {
    return (
      <NativeTouchableOpacity
        onPress={onPress}
        style={[
          theme.background.primary10,
          theme.padding.l,
          theme.margin.topm,
          theme.borderRadius.m,
          theme.border.primary50,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            backgroundColor: '#1a1447FF',
            marginLeft: 14,
            marginRight: 14,
          },
        ]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            style={[homeDrawerStyle.close, theme.margin.rightl]}
            source={source}
          />
          <Text white fontSize={theme.fontSize.l}>
            {title}
          </Text>
          <View style={theme.flex.flex1} />
          {num != null && num > 0 && <Tag content={num} />}
          {showDownIcon ? (
            <Image
              style={[homeDrawerStyle.down]}
              source={require('@assets/icons/down.webp')}
            />
          ) : null}
        </View>
      </NativeTouchableOpacity>
    );
  };
  // const snsList = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  return (
    <ScrollView
      style={[
        theme.fill.fillW,
        theme.flex.col,
        theme.background.mainDark,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          height: globalStore.screenHeight,
          paddingBottom: 100,
        },
      ]}>
      {/* 用户信息 */}
      <DrawerUser
        login={login}
        user={user}
        level={level}
        onClose={onCloseDrawerMenu}
      />
      {/* 邀请好友 */}
      <NativeTouchableOpacity
        onPress={() => {
          onCloseDrawerMenu();
          goTo('InviteFriends');
        }}
        style={[
          theme.position.rel,
          {marginLeft: 15, marginRight: 15, marginTop: 8, marginBottom: 4},
        ]}>
        <LinearGradient
          start={{x: 0, y: 0}}
          end={{x: 1, y: 0}}
          colors={theme.linearGradientColor.chestLinearGradient}
          style={[
            theme.position.abs,
            theme.fill.fillW,
            theme.borderRadius.m,
            {
              height: scaleSize(60),
              bottom: 0,
              left: 0,
              zIndex: 1,
              marginTop: 8,
            },
          ]}>
          <View
            style={[
              theme.flex.centerByRow,
              theme.flex.flex1,
              {marginLeft: scaleSize(80)},
            ]}>
            <Text color={'#0BD064'} fontSize={theme.fontSize.l} blod>
              Invite Friends
            </Text>
            <Text fontSize={theme.fontSize.s} blod white>
              You all get cash
            </Text>
          </View>
        </LinearGradient>
        <Image
          style={[
            {
              width: scaleSize(74),
              height: scaleSize(74),
              zIndex: 2,
            },
          ]}
          source={require('@assets/icons/home/home-menu/invite_left.webp')}
          resizeMode="contain"
        />
        <Image
          style={[
            {
              width: scaleSize(87),
              height: scaleSize(87),
              zIndex: 2,
              position: 'absolute',
              top: scaleSize(0),
              right: scaleSize(0),
            },
          ]}
          source={require('@assets/icons/home/home-menu/invite_right.webp')}
          resizeMode="contain"
        />
      </NativeTouchableOpacity>
      {/* 竖排导航栏 */}
      <View
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          marginLeft: 14,
          marginRight: 14,
          borderRadius: 5,
        }}>
        {[
          {
            icon: require('@assets/icons/home/home-menu/home.webp'),
            label: i18n.t('home.tab.home'),
            onPress: () => {
              onCloseDrawerMenu();
              useHomeStore.setState({oneCategoryPageIndex: 0});
            },
          },
          {
            icon: require('@assets/icons/home/home-menu/lottery.webp'),
            label: i18n.t('home.tab.lottery'),
            onPress: () => {
              onCloseDrawerMenu();
              useHomeStore.setState({oneCategoryPageIndex: 1});
            },
          },
          {
            icon: require('@assets/icons/home/home-menu/game.webp'),
            label: i18n.t('home.tab.casino'),
            onPress: () => {
              onCloseDrawerMenu();
              useHomeStore.setState({
                oneCategoryPageIndex: 2,
                pageTagIndex: -1,
              });
            },
          },
          {
            icon: require('@assets/imgs/proxy/live.webp'),
            label: i18n.t('home.tab.liveCasino'),
            onPress: () => {
              onCloseDrawerMenu();
              useHomeStore.setState({
                oneCategoryPageIndex: 3,
                pageTagIndex: -1,
              });
            },
          },
          {
            icon: require('@assets/icons/home/home-menu/sports.webp'),
            label: i18n.t('home.tab.sports'),
            onPress: () => {
              onCloseDrawerMenu();
              globalStore.globalTotal.next({
                type: 'warning',
                message: i18n.t('warning.unopen'),
              });
            },
          },
        ].map(v => (
          <NativeTouchableOpacity
            key={`${v.label}`}
            onPress={v?.onPress}
            style={[
              theme.padding.l,
              theme.border.primary50,
              theme.borderRadius.s,
              // theme.margin.topl,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                backgroundColor: '#1a1447',
                paddingTop: 7,
                paddingBottom: 7,
                marginTop: 8,
              },
            ]}>
            <View
              style={[
                theme.flex.row,
                theme.flex.between,
                theme.flex.centerByCol,
              ]}>
              <View style={[theme.flex.row, theme.flex.centerByCol]}>
                <Image
                  style={[
                    homeDrawerStyle.close,
                    theme.margin.rightl,
                    {width: 32, height: 32},
                  ]}
                  source={v.icon}
                />
                <Text white fontSize={theme.fontSize.l}>
                  {v.label}
                </Text>
              </View>

              <Image
                style={[homeDrawerStyle.itemIcon, theme.margin.rightzorro]}
                source={require('@assets/icons/home/home-menu/arrow.webp')}
              />
            </View>
          </NativeTouchableOpacity>
        ))}
      </View>
      {/* 活动页 */}
      <NativeTouchableOpacity
        onPress={() => {
          onCloseDrawerMenu();
          goTo('Promotion');
        }}
        style={[
          theme.position.rel,
          {marginLeft: 15, marginRight: 15, marginTop: 8},
        ]}>
        <LinearGradient
          start={{x: 0, y: 0}}
          end={{x: 1, y: 0}}
          colors={theme.linearGradientColor.homemenuPromotion}
          style={[
            theme.position.abs,
            theme.fill.fillW,
            theme.borderRadius.m,
            {
              height: scaleSize(60),
              bottom: 0,
              left: 0,
              zIndex: 1,
            },
          ]}>
          <View
            style={[
              theme.flex.centerByRow,
              theme.flex.flex1,
              {marginLeft: scaleSize(80)},
            ]}>
            <Text fontSize={theme.fontSize.l} blod color={'#F6CB40'}>
              Promotion
            </Text>
            <Text fontSize={theme.fontSize.s} blod white>
              There are many offers
            </Text>
          </View>
        </LinearGradient>
        <Image
          style={[
            {
              width: scaleSize(74),
              height: scaleSize(74),
              zIndex: 2,
            },
          ]}
          source={require('@assets/icons/home/home-menu/promotion_l.webp')}
          resizeMode="contain"
        />
        <Image
          style={[
            {
              width: scaleSize(87),
              height: scaleSize(87),
              zIndex: 2,
              position: 'absolute',
              top: scaleSize(0),
              right: scaleSize(0),
            },
          ]}
          source={require('@assets/icons/home/home-menu/promotion_r.webp')}
          resizeMode="contain"
        />
      </NativeTouchableOpacity>
      {/* 横排导航栏 */}
      <DrawerGoldArea
        onClose={onCloseDrawerMenu}
        spinShow={() => {
          goTo('LuckySpinPage');
        }}
      />
      {/* 代理页 */}
      <NativeTouchableOpacity
        onPress={() => {
          onCloseDrawerMenu();
          goTo('AgentFranchise');
        }}
        style={[
          theme.position.rel,
          {marginLeft: 15, marginRight: 15, marginBottom: 0, marginTop: -12},
        ]}>
        <LinearGradient
          start={{x: 0, y: 0}}
          end={{x: 1, y: 0}}
          colors={theme.linearGradientColor.chestLinearGradient}
          style={[
            theme.position.abs,
            theme.fill.fillW,
            theme.borderRadius.m,
            {
              height: scaleSize(60),
              bottom: 0,
              left: 0,
              zIndex: 1,
            },
          ]}>
          <View
            style={[
              theme.flex.centerByRow,
              theme.flex.flex1,
              {marginLeft: scaleSize(80)},
            ]}>
            <Text color={'#33A3FF'} fontSize={theme.fontSize.l} blod>
              Become an agent
            </Text>
            <Text fontSize={theme.fontSize.s} blod white>
              Can make money
            </Text>
          </View>
        </LinearGradient>
        <Image
          style={[
            {
              width: scaleSize(74),
              height: scaleSize(74),
              zIndex: 2,
            },
          ]}
          source={require('@assets/icons/home/home-menu/agency_l.webp')}
          resizeMode="contain"
        />
        <Image
          style={[
            {
              width: scaleSize(72),
              height: scaleSize(72),
              zIndex: 2,
              position: 'absolute',
              top: scaleSize(6),
              right: scaleSize(0),
            },
          ]}
          source={require('@assets/icons/home/home-menu/agency_r.webp')}
          resizeMode="contain"
        />
      </NativeTouchableOpacity>

      {globalStore.isWeb && !globalStore.viewType
        ? renderFooterBtn({
            title: i18n.t('me.bottom.download'),
            source: require('@assets/icons/home/home-menu/downApp.webp'),
            onPress: () => {
              onCloseDrawerMenu();
              if (checkToken()) {
                downloadApk();
              }
            },
          })
        : null}

      {renderFooterBtn({
        title: i18n.t('me.bottom.customer'),
        source: require('@assets/icons/home/home-menu/customer.webp'),
        onPress: () => {
          onCloseDrawerMenu();
          goCS();
        },
      })}

      {renderFooterBtn({
        title: languagesMap[globalStore.lang],
        source: require('@assets/icons/home/home-menu/England.webp'),
        onPress: showLanguageModal,
        showDownIcon: true,
      })}
      {renderModal}
      {/* 社会网络展示 目前先注释掉 */}
      {/* <DrawerSns list={snsList as any} /> */}
    </ScrollView>
  );
};

export default HomeMenu;
