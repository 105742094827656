import {
  View,
  Platform,
  LayoutChangeEvent,
  RefreshControl,
  Animated,
  Image,
} from 'react-native';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React, {useCallback, useRef, useState} from 'react';
import theme from '@style';
import {goTo, goCS, goToWithLogin} from '@utils'; //toAgentApply,
import Text from '@basicComponents/text';
import {VipProgress} from '@businessComponents/vip';
import {
  MeListItem,
  gamesIcon,
  // collectIcon,
  betsIcon,
  rebateIcon,
  transactionsIcon,
  updateIcon,
  passwordIcon,
  customerServiceIcon,
  languagesIcon,
  notificationsIcon,
  resultHistoryIcon,
  shopIcon,
  couponIcon,
} from '@businessComponents/list-item';
import {useConfirm} from '@basicComponents/modal';
import {useLanguageModal} from '@businessComponents/language';
import {useFocusEffect} from '@react-navigation/native';
import globalStore from '@/services/global.state';
import {toLogin} from './me.variable';
import MeUser from './me-user';
import MeAmount from './me-amount';
import MeVip from './me-vip';
import {useVersionModal} from '@/common-pages/hooks/versionmodal.hooks';
import {getVersion} from 'react-native-device-info';
import Spin from '@/components/basic/spin';
import {useTranslation} from 'react-i18next';
import MeRowMenu from './me-row-menu';
import Tag from '@/components/basic/tag';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import useVipStore, {useVipActions} from '@/store/useVipStore';
import {LazyImageLGBackground} from '@/components/basic/image';
import useUserStore, {useUserActions, useUserInfo} from '@/store/useUserStore';
// import MeRowBtn from './me-row-btn';
import Button from '@/components/basic/button';
import useNotificationStore from '@/store/useNotificationStore';
import {useShallow} from 'zustand/react/shallow';
import {useSettingWindowDimensions} from '@/store/useSettingStore';

const {overflow, padding, font, margin, borderRadius, background} = theme;

/** TODO 单个文件过大,需要拆解 */
const Me = () => {
  const {i18n} = useTranslation();
  const [login, setLogin] = useState<boolean>(false);

  const {screenWidth} = useSettingWindowDimensions();
  const scrollAnim = useRef(new Animated.Value(0)).current;
  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm();
  const {renderModal: renderLanguageModal, show: languageShow} =
    useLanguageModal();
  const {versionModal, handleUpdate} = useVersionModal(false);

  const [_, setUserAreaY] = useState<number>(0);
  const firstFocus = useRef(true);
  const [pageLoading, setPageLoading] = useState(false);

  const {level, nextValue, diff} = useVipStore(state => state.vipInfo);
  const {setVipConfig, setVipInfo} = useVipActions();
  const user = useUserInfo();
  const {getUserInfo} = useUserActions();
  const {noticeMap, getNoticeMap, unReadMessageCount, getUnReadCount} =
    useNotificationStore(
      useShallow(state => ({
        noticeMap: state.noticeMap,
        getNoticeMap: state.getNoticeMap,
        unReadMessageCount: state.unReadMessageCount,
        getUnReadCount: state.getUnReadCount,
      })),
    );

  const refresh = useCallback(
    async (token: string | null, showloading = true) => {
      showloading && setPageLoading(true);
      try {
        if (!token) {
          setLogin(false);
          setVipConfig();
        } else {
          setLogin(true);
          setVipInfo();
          getUserInfo();
        }
      } finally {
        setPageLoading(false);
        setRefreshing(false);
      }
    },
    [getUserInfo, setVipConfig, setVipInfo],
  );

  const onFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
      refresh(token, firstFocus.current);
      firstFocus.current = false;
      if (token) {
        getNoticeMap();
        getUnReadCount();
        globalStore.amountCheckOut.next();
      }
    });
    const msgSub = globalStore.notificationSubject.subscribe(_countInfo => {});
    return () => {
      sub.unsubscribe();
      msgSub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  useFocusEffect(onFocusEffect);

  const handleUser = () => {
    if (!login) {
      toLogin();
      return;
    }
    // if (showNoMenu) {
    //   globalStore.globalTotal.next(notYetWarning);
    // }
  };

  const handleMoneyLayout = (e: LayoutChangeEvent) => {
    setUserAreaY(e.nativeEvent.layout.y);
  };

  const handleRefresh = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    refresh(globalStore.token);
  };

  const toVip = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Vip');
  };

  // const toAgency = () => {
  //   // 开关，后期如果有条件可换成动态flag
  //   const oldFlag = false;
  //   if (!login) {
  //     toLogin();
  //     return;
  //   }
  //   if (user?.isAgent === 1) {
  //     if (oldFlag) {
  //       goTo('ProxyHome');
  //     } else {
  //       goTo('NewProxyHome');
  //     }
  //   } else {
  //     toAgentApply();
  //   }
  // };

  // const toInvitation = () => {
  //   goTo('Invitation');
  // };

  const toRebate = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转commission
    goTo('Rebate');
  };

  const toMyGames = () => {
    goToWithLogin('MyGames');
  };

  // const toMyCollect = () => {
  //   goTo('CollectPage');
  // };

  const toShop = () => {
    goTo('ShoppingPage');
  };

  const toCoupon = () => {
    goTo('CouponPage');
  };

  const toTransactions = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Transactions');
  };

  const toPromotion = () => {
    goTo('PromotionDetail', {id: 4});
  };

  const toLanguage = () => {
    languageShow();
  };

  const toUpdate = () => {
    // 更新
    handleUpdate();
  };

  const doLogout = () => {
    // logout
    confirmShow(i18n.t('alert.logout'), i18n.t('alert.sureLogout'), () => {
      globalStore.token = null;
      globalStore.userInfo = null;
      // loginOut();
      useUserStore.getState().loginOut();
      toLogin();
    });
  };

  const toMyBets = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Bets');
  };

  const toNotify = () => {
    if (!login) {
      goTo('Login');
    } else {
      goTo('Notification');
    }
  };

  const toSetPassword = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('SetPassword');
  };

  const toResults = () => {
    goTo('Result');
  };

  const [refreshing, setRefreshing] = useState<boolean>(false);
  return (
    <LazyImageLGBackground showBottomBG={false} subtractBottomTabHeight>
      {/* TODO 这里的滚动方案需要优化,以及文件过大需要拆分 */}
      <Spin loading={pageLoading} style={[theme.fill.fill, theme.padding.lrl]}>
        <Animated.ScrollView
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {y: scrollAnim},
                },
              },
            ],
            {useNativeDriver: true},
          )}
          showsVerticalScrollIndicator={false}
          scrollEventThrottle={1}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRefreshing(true);
                refresh(globalStore.token, false);
              }}
            />
          }>
          <DetailNavTitle hideAmount hideServer title={i18n.t('home.tab.me')} />
          <MeUser
            login={login}
            user={user}
            level={level}
            onUser={handleUser}
            showNoMenu={false}
          />
          <MeVip
            login={login}
            level={level}
            onPress={toVip}
            nextLevelValue={nextValue}
            renderProgress={
              <VipProgress
                currentLevel={level}
                nextCurrentLevel={level + 1}
                current={nextValue - diff}
                total={nextValue}
                hasCurrentText={true}
              />
            }
          />
          <MeAmount onLayout={handleMoneyLayout} onRefresh={handleRefresh} />
          <MeRowMenu />
          <NativeTouchableOpacity activeOpacity={1} onPress={toPromotion}>
            <Image
              source={require('@assets/imgs/me/advertisement.webp')}
              style={[{height: 68, width: '100%'}, theme.margin.tops]}
            />
          </NativeTouchableOpacity>
          <View
            style={[
              margin.topl,
              theme.flex.row,
              theme.padding.lrl,
              theme.flex.centerByCol,
              theme.background.mainDark,
              theme.borderRadius.s,
              theme.flex.between,
              // eslint-disable-next-line react-native/no-inline-styles
              {height: 53},
            ]}>
            <View
              style={[
                theme.flex.flex1,
                theme.flex.centerByRow,
                theme.background.mainDark,
                theme.borderRadius.s,
                // eslint-disable-next-line react-native/no-inline-styles
                {height: 53},
              ]}>
              <Text numberOfLines={1} color={'white'}>
                You have {noticeMap?.REBATE} bet rebates to claim
              </Text>
              <Text numberOfLines={1} color={theme.fontColor.white60}>
                Your bet will be settled at 12:00 every day.
              </Text>
            </View>
            <Button
              title="Draw down"
              size="small"
              type="linear-primary"
              radius={30}
              onPress={toRebate}
            />
          </View>
          {/* <MeRowBtn onInvite={toInvitation} onProxy={toAgency} /> */}
          <View style={[]}>
            {/* 列表区域 */}
            <View style={[borderRadius.m, overflow.hidden, margin.topxxxs]}>
              {/* coupon位置调整 */}
              <MeListItem
                icon={couponIcon}
                title={i18n.t('me.bottom.coupon')}
                description="Your get coupon are here"
                onPress={toCoupon}
              />
              <MeListItem
                icon={gamesIcon}
                title={i18n.t('me.bottom.games')}
                description="Your favorite games are here"
                onPress={toMyGames}
              />
              {/* <MeListItem
                icon={collectIcon}
                title={i18n.t('me.bottom.collect')}
                description="Get the collect and get bonus rewards"
                onPress={toMyCollect}
              /> */}
              <MeListItem
                icon={rebateIcon}
                title={i18n.t('home.label.rebate')}
                description="View the return history of the bet amount"
                onPress={toRebate}
                rightContent={
                  noticeMap?.REBATE ? <Tag content={noticeMap?.REBATE} /> : null
                }
              />
              <MeListItem
                icon={transactionsIcon}
                title={i18n.t('me.bottom.myTransactions')}
                description="View all transaction records"
                onPress={toTransactions}
              />
              <MeListItem
                icon={betsIcon}
                title={i18n.t('me.bottom.myBets')}
                description="View the betting record"
                onPress={toMyBets}
              />
              <MeListItem
                icon={resultHistoryIcon}
                title={i18n.t('me.bottom.resultHistory')}
                description="View the historical results of the lottery"
                onPress={toResults}
              />
              <MeListItem
                icon={shopIcon}
                title={i18n.t('me.bottom.shop')}
                description="Use your coins to exchange goods"
                onPress={toShop}
              />
            </View>
            <View
              style={[
                borderRadius.m,
                theme.background.mainDark,
                overflow.hidden,
                margin.topl,
              ]}>
              <MeListItem
                containerStyle={[theme.padding.tbl]}
                icon={notificationsIcon}
                iconSize={18}
                title={i18n.t('me.bottom.notify')}
                rightContent={
                  unReadMessageCount?.messageTotalCount ? (
                    <Tag
                      badgeSize={16}
                      backgroundColor={theme.basicColor.red}
                      content={unReadMessageCount?.messageTotalCount}
                    />
                  ) : null
                }
                mt={0}
                onPress={toNotify}
              />

              <MeListItem
                icon={passwordIcon}
                iconSize={18}
                containerStyle={[theme.padding.tbl]}
                mt={0}
                title={i18n.t('me.bottom.password')}
                onPress={toSetPassword}
              />

              <MeListItem
                containerStyle={[theme.padding.tbl]}
                mt={0}
                iconSize={18}
                icon={languagesIcon}
                title={i18n.t('me.bottom.lang')}
                onPress={toLanguage}
              />
              <MeListItem
                containerStyle={[theme.padding.tbl]}
                mt={0}
                iconSize={18}
                icon={customerServiceIcon}
                title={i18n.t('me.bottom.customer')}
                onPress={goCS}
                hideBottomBorder={Platform.OS === 'android' ? false : true}
              />
              {Platform.OS === 'android' && (
                <MeListItem
                  containerStyle={[theme.padding.tbl]}
                  icon={updateIcon}
                  iconSize={18}
                  title={i18n.t('me.bottom.update')}
                  rightContent={
                    <Text style={[font.secAccent, font.s]}>
                      {getVersion()} {globalStore.channel}
                    </Text>
                  }
                  mt={0}
                  onPress={toUpdate}
                />
              )}
            </View>
            {login && (
              <NativeTouchableOpacity onPress={doLogout}>
                <View
                  style={[
                    background.mainShallow,
                    padding.lrm,
                    padding.tbl,
                    borderRadius.m,
                    overflow.hidden,
                    margin.topl,
                  ]}>
                  <Text style={[font.white, font.m, font.bold, font.center]}>
                    {i18n.t('me.bottom.logout')}
                  </Text>
                </View>
              </NativeTouchableOpacity>
            )}
          </View>
          <Image
            source={require('@assets/imgs/footer-image.webp')}
            // eslint-disable-next-line react-native/no-inline-styles
            style={[{height: 180, width: screenWidth}]}
          />
        </Animated.ScrollView>
        {/* <MeHeader
          user={user}
          userAreaY={userAreaY}
          login={login}
          scrollAnim={scrollAnim}
          onUser={handleUser}
          showNoMenu={showNoMenu}
        /> */}
      </Spin>

      {renderConfirmModal}
      {renderLanguageModal}
      {versionModal.renderModal}
    </LazyImageLGBackground>
  );
};

export default Me;
